// ==========================================================================
// Typography
// ==========================================================================
html,
body {
  font-family: $font-family--primary;
  font-size: 1.6rem;
}

h1,
h2,
h3,
h4,
h5,
h6{
  font-weight: $font-weight--normal;
}

%body-copy {
  font-size: 1.6rem;
  font-weight: 300;
  letter-spacing: 0.04em;
  line-height: 1.7;
  @media #{$laptop} {
    font-size: 1.8rem;
  }
}

%heading {
  font-size: 1.8rem;
  font-weight: 500;
  letter-spacing: 0.04em;
  line-height: 1.4;
  @media #{$laptop} {
    font-size: 2rem;
  }
}

%section-title-text-sm {
  @extend %font-smoothing;
  font-family: $font-family--secondary;
  font-size: 1.8rem;
  font-weight: 400;
  letter-spacing: 0.2em;
  @media #{$laptop} {
    font-size: 2rem;
  }
}

%section-title-text-md {
  @extend %font-smoothing;
  font-family: $font-family--secondary;
  font-size: 2.2rem;
  font-weight: 400;
  @media #{$laptop} {
    font-size: 2.8rem;
  }
}

%section-title-text-lg {
  font-family: $font-family--secondary;
  font-size: 2.6rem;
  font-weight: 400;
  line-height: 1.4;
  @media #{$tablet} {
    font-size: 3.6rem;
  }
  @media #{$laptop} {
    font-size: 4.4rem;
  }
}

%highlight-heading-text {
  @extend %heading;
}

%highlight-copy-text {
  @extend %body-copy;
}

%button-text {
  @extend %font-smoothing;
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 0.2em;
  @media #{$laptop} {
    font-size: 1.4rem;
    letter-spacing: 0.3em;
  }
}

%nav-link-text {
  font-size: 1.6rem;
  letter-spacing: 0.04em;
  font-weight: 500;
  color: rgba($color--primary, 0.8);
  @media #{$desktop} {
    font-size: 1.8rem;
  }
}

%nav-button-text {
  font-size: 1.2rem;
  letter-spacing: 0.3em;
  font-weight: 700;
  @extend %font-smoothing;
  @media #{$desktop} {
    font-size: 1.4rem;
  }
}

%hero-title-text {
  font-family: $font-family--secondary;
  font-size: 2.8rem;
  font-weight: 400;
  line-height: 1.4;
  @media #{$tablet} {
    font-size: 3.8rem;
  }
  @media #{$laptop} {
    font-size: 4.8rem;
  }
  @media #{$desktop} {
    font-size: 5rem;
  }
}

%hero-highlight-heading-text {
  @extend %heading;
}

%hero-highlight-copy-text {
  @extend %body-copy;
}

%reviews-title-text {
  @extend %section-title-text-sm;
}

%reviews-quote-text {
  font-size: 1.8rem;
  line-height: 1.6;
  font-weight: 300;
  @media #{$laptop} {
    font-size: 2rem;
  }
}

%reviews-quote-author-text {
  font-size: 2rem;
  font-weight: 500;
  @media #{$laptop} {
    font-size: 2.2rem;
  }
}

%reviews-quote-title-text {
  font-size: 1.4rem;
  letter-spacing: 0.2em;
  @media #{$laptop} {
    font-size: 1.6rem;
  }
}

%steps-intro-title-text {
  @extend %section-title-text-lg;
}

%steps-intro-text {
  font-size: 1.6rem;
  font-weight: 300;
  margin: 0.5rem 0 1.5rem;
  text-align: center;
  @media #{$tablet} {
    font-size: 1.8rem;
  }
  @media #{$laptop} {
    font-size: 2rem;
  }
}

%steps-item-title-text {
  @extend %heading;
}

%steps-item-copy-text {
  @extend %body-copy;
}

%options-title-text {
  @extend %section-title-text-lg;
}

%options-item-text {
  @extend %heading;
}

%options-cta-text {
  font-size: 1.8rem;
  font-weight: 600;
  letter-spacing: 0.04em;
  @extend %font-smoothing;
  @media #{$laptop} {
    font-size: 2.4rem;
  }
}

%facts-title-text {
  @extend %section-title-text-sm;
}

%facts-heading-text {
  font-size: 2.8rem;
  line-height: 1.4;
}

%facts-copy-text {
  @extend %body-copy;
}

%quote-text {
  font-size: 1.8rem;
  line-height: 1.6;
  @media #{$tablet} {
    font-size: 2.2rem;
  }
  @media #{$laptop} {
    font-size: 2.4rem;
  }
}

%quote-author-text {
  font-size: 2rem;
  font-weight: 500;
  @media #{$laptop} {
    font-size: 2.2rem;
  }
}

%quote-title-text {
  font-size: 1.4rem;
  letter-spacing: 0.2em;
  @media #{$laptop} {
    font-size: 1.6rem;
  }
}

%plans-title-text {
  @extend %section-title-text-lg;
}

%plans-highlight-heading-text {
  @extend %heading;
}

%plans-highlight-copy-text {
  @extend %body-copy;
}

%plan-title-text {
  @extend %section-title-text-md;
}

%plan-price-text {
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: 0.04em;
  line-height: 1.4;
  @media #{$laptop} {
    font-size: 2rem;
  }
}

%plan-description-text {
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.04em;
  line-height: 1.4;
}

%plan-frequency-text {
  font-size: 1.4rem;
  font-weight: 300;
  letter-spacing: 0.04em;
  line-height: 1.4;
  @media #{$laptop} {
    font-size: 1.6rem;
  }
}

%plan-cost-text {
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: 0.04em;
  line-height: 1.4;
  @media #{$laptop} {
    font-size: 1.6rem;
  }
}
%faqs-title-text {
  @extend %section-title-text-md;
}
%faqs-copy-text {
  @extend %body-copy;
}

%faqs-modal-title-text {
  @extend %section-title-text-lg;
}
%faqs-modal-question-text {
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.04em;
  line-height: 1.4;
  @media #{$laptop} {
    font-size: 2.8rem;
  }
}
%faqs-modal-answer-text {
  @extend %body-copy;
}

%orthodontist-title-text {
  @extend %section-title-text-md;
}

%orthodontist-copy-text {
  @extend %body-copy;
}

%orthodontist-modal-title-text {
  @extend %section-title-text-lg;
}

%orthodontists-modal-highlight-heading-text {
  @extend %highlight-heading-text;
}

%orthodontists-modal-highlight-copy-text {
  @extend %highlight-copy-text;
}

%orthodontist-modal-form-lead-text {
  @extend %heading;
}

%orthodontist-modal-form-heading-text {
  @extend %section-title-text-lg;
}

%contact-lead-text {
  @extend %heading;
}
%contact-heading-text {
  @extend %section-title-text-lg;
}

%input-text {
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: 0.04em;
  line-height: 1.4;
  @media #{$laptop} {
    font-size: 2rem;
  }
}

%form-response {
  font-size: 1.4rem;
}

%footer-text {
  @extend %font-smoothing;
  font-size: 1.6rem;
  font-weight: 300;
  letter-spacing: 0.04em;
  line-height: 1.4;
  @media #{$laptop} {
    font-size: 2rem;
  }
}

%footer-address-text {
  @extend %font-smoothing;
  font-size: 1.6rem;
  font-weight: 300;
  letter-spacing: 0.04em;
  line-height: 2;
}

%footer-contact-text {
  @extend %font-smoothing;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.04em;
  line-height: 1.4;
  @media #{$laptop} {
    font-size: 1.8rem;
  }
}

%footer-newsletter-text {
  @extend %font-smoothing;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.04em;
  line-height: 1.4;
  @media #{$laptop} {
    font-size: 2rem;
  }
}

%newsletter-input-text {
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0.04em;
  @media #{$laptop} {
    font-size: 1.6rem;
  }
}

%newsletter-submit-text {
  @extend %font-smoothing;
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 0.04em;
  @media #{$laptop} {
    font-size: 1.4rem;
  }
}

%legal-text {
  @extend %font-smoothing;
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 0.04em;
  line-height: 1.4;
  @media #{$laptop} {
    font-size: 1.4rem;
  }
}