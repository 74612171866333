// ==========================================================================
// Extends
// ==========================================================================

// Clearfix
  %clearfix {
    &:after {
      content: "";
      clear: both;
      display: table;
    }
  }

  %font-smoothing {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  %section-title-alt {
    // @extend %text-wide;
    padding: 1.5rem 2rem;
    position: relative;
    text-align: center;
    z-index: 1;
    @media #{$desktop} {
      padding: 3rem 2rem;
    }
  }

  %divider-svg {
    svg {
      animation: dividerAnimation 6s linear infinite;
      height: 100%;
      stroke-dasharray: 2,5;
      stroke-width: 2;
      stroke: $gray;
      width: 0.2rem;
    }
  }
  @keyframes dividerAnimation {
    0% {
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: -100;
    }
  }

  %pagers {
    font-size: 0;
    text-align: center;
    span,
    .dot {
      background: $color--secondary;
      border-radius: 100%;
      cursor: pointer;
      display: inline-block;
      height: 0.8rem;
      margin-right: 1.5rem;
      opacity: 0.3;
      width: 0.8rem;
      &:last-child {
        margin-right: 0;
      }
      &.active,
      &.is-selected {
        opacity: 1;
      }
      @media #{$laptop} {
        height: 1rem;
        margin-right: 2rem;
        width: 1rem;
      }
    }
  }