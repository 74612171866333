.about {
  background-color: $color--bg-light;
}
.steps {
  background: #fff;
  opacity: 0;
  overflow: hidden;
  padding: 8rem 2rem 4rem;
  transition: 0.8s ease-out;
  &.active {
    opacity: 1;
  }
  @media #{$laptop} {
    background: $color--bg-light;
    padding: 10rem 0 8rem;
  }
  @media #{$desktop} {
    padding: 14rem 0 8rem;
  }
  &-title {
    @extend %steps-intro-title-text;
    color: $color--secondary;
    text-align: center;
  }
  &-text {
    @extend %steps-intro-text;
  }
  &-ctn {
    max-width: 50rem;
    margin: 0 auto;
    padding: 2rem 0;
    position: relative;
    @media #{$laptop} {
      max-width: none;
      padding: 6rem 0 2rem;
    }
  }
  &-img {
    &-ctn {
      display: none;
      @media #{$laptop} {
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 50%;
      }
    }
    & {
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      display: block;
      height: 80%;
      right: 20%;
      position: absolute;
      top: 10%;
      width: 60%;
      max-width: 42rem;
    }
  }
  &-item {
    max-width: 50rem;
    padding: 1rem 0;
    position: relative;
    @media #{$laptop} {
      max-width: 56rem;
      margin-left: 50%;
      text-align: left;
    }
    &-icon {
      display: block;
      height: 5rem;
      left: 0;
      margin: 0 auto 1.5rem;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      width: 5rem;
      @media #{$laptop} {
        height: 6rem;
        left: -4rem;
        width: 6rem;
      }
    }
    &-content {
      padding-bottom: 2rem;
      position: relative;
      @media #{$laptop} {
        padding-bottom: 4rem;
      }
    }
    &-title {
      &-ctn {
        margin-bottom: 1rem;
        padding: 0 2rem 0 7rem;
        position: relative;
        @media #{$laptop} {
          margin-bottom: 1rem;
          padding: 0 8rem;
        }
      }
      & {
        @extend %steps-item-title-text;
        color: $color--secondary;
      }
    }
    &-text {
      &-ctn {
        padding: 0 2rem 0 7rem;
        position: relative;
        min-height: 5rem;
        @media #{$laptop} {
          min-height: 8rem;
          padding: 0 8rem;
        }
      }
      & {
        @extend %steps-item-copy-text;
      }
    }
    &-content & {
      &-title,
      &-text {
        opacity: 0;
        transition: 0.6s ease-out;
      }
    }
    &.active &-content & {
      &-title,
      &-text {
        transition: 1s ease-out;
        transform: translate(5rem, 0);
      }
    }
    &.active &-content & {
      &-title,
      &-text {
        opacity: 1;
        transform: translate(0, 0);
      }
    }
    &-divider {
      @extend %divider-svg;
      height: 0;
      left: 2.5rem;
      margin-left: -0.1rem;
      position: absolute;
      top: 1.5rem;
      transition: 0.6s ease-out;
      width: 0.2rem;
      @media #{$laptop} {
        left: 0;
        margin-left: -0.2rem;
        top: 2.5rem;
      }
    }
    &-icon {
      &.camera-icon {
        stroke-dasharray: 142;
        stroke-dashoffset: 142;
      }
      &.calendar-icon {
        stroke-dasharray: 140;
        stroke-dashoffset: 140;
      }
      &.mouthguard-icon {
        stroke-dasharray: 89;
        stroke-dashoffset: 89;
      }
      transition: 0.6s ease-out;
    }
    &:not(.active) &-icon .bg {
      & path {
        opacity: 0;
        stroke-dasharray: 0;
        stroke-dashoffset: 0;
        transition: 0.6s ease-out 0s;
      }
      & circle {
        fill: $color--bg-light !important;
        transition: 0.6s ease-out 0s;
      }
    }
    &.active &-icon {
      stroke-dashoffset: 0;
      transition: 1s ease-in-out;
      .bg {
        & path {
          opacity: 1;
          stroke-dasharray: 0;
          stroke-dashoffset: 0;
          transition: 0.6s ease-out 0.6s;
        }
        & circle {
          transition: 0.6s ease-out 0.6s;
        }
      }
    }
    &.active & {
      &-divider {
        height: 100%;
        transition: 0.8s ease-out;
      }
    }
    &:last-child &-divider {
      display: none;
    }
  }
  &-cta {
    display: none;
    @media #{$laptop} {
      display: block;
      position: relative;
      text-align: center;
      z-index: 1;
    }
  }
}

.options {
  background: #fff;
  overflow: hidden;
  padding: 0 0 4rem;
  @media #{$laptop} {
    background-color: $color--bg-light;
    padding: 12rem 4rem 8rem;
    &:before {
      background: linear-gradient($color--bg-light, $color--bg-dark 10%);
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 0;
    }
  }
  &:after {
    background-image: url(./assets/img/white-dot-bg.png);
    background-repeat: repeat-x;
    background-size: auto 100%;
    content: "";
    display: block;
    height: 60%;
    left: 0;
    position: absolute;
    bottom: 0;
    transform-origin: 100% 0;
    transform: rotate(-1deg);
    width: 100%;
    z-index: 1;
  }
  &-content {
    position: relative;
    text-align: center;
    z-index: 2;
    @media #{$laptop} {
      background-color: #fff;
      border-radius: 0.6rem;
      margin: 0 auto;
      max-width: 98rem;
      overflow: hidden;
    }
  }
  &-intro {
    background-color: $color--bg-light;
    padding: 1rem 2rem 4rem;
    transition: 0.6s ease-out;
    @media #{$laptop} {
      padding: 3rem 8rem 6rem;
    }
    @media #{$mac} {
      padding: 4rem 8rem 6rem;
    }
  }
  &-icon {
    display: block;
    height: 8rem;
    margin: 0 auto;
    stroke-dasharray: 111;
    stroke-dashoffset: 111;
    transition: 0.6s ease-out;
    width: 8rem;
    @media #{$laptop} {
      height: 10rem;
      width: 10rem;
    }
  }
  &-intro:not(.active) &-icon .bg path {
    fill: $color--bg-light !important;
    transition: 0.6s ease-out;
  }
  &-title {
    @extend %options-title-text;
    color: $color--secondary;
    opacity: 0;
    text-align: center;
    text-transform: uppercase;
    transition: 0.6s ease-out;
    @media #{$laptop} {
      padding: 0 0 2rem;
    }
  }
  &-intro.active & {
    &-icon {
      stroke-dashoffset: 0;
      transition: 0.8s ease-in-out;
      .bg path{
        transition: 1s ease-out 0.2s;
      }
    }
    &-title{
      opacity: 1;
      transition: 1s ease-out 0.4s;
    }
  }
  &-list {
    &-ctn {
      width: 100%;
      margin-top: 4rem;
      position: relative;
      @media #{$tablet} {
        margin-top: 6rem;
        margin-bottom: 2rem;
      }
    }
    & {
      display: block;
      max-width: 30rem;
      margin: 0 auto;
      @media #{$tablet} {
        padding-left: 55%;
        max-width: none;
      }
    }
    &-item {
      @extend %options-item-text;
      color: $color--secondary;
      display: block;
      line-height: 3rem;
      opacity: 0;
      padding: 1rem 0.5rem;
      text-align: left;
      transition: 0.6s ease-out;
      transition-delay: 0;
      @media #{$laptop} {
        line-height: 3.6rem;
        padding: 2rem 3rem;
      }
      a {
        display: inline-block;
        padding: 0 0.5rem 0 2.5rem;
        position: relative;
        @media #{$laptop} {
          padding: 0 0.5rem 0 4.8rem;
        }
        svg {
          display: block;
          fill: none;
          height: 2.4rem;
          left: 0;
          margin: 0.25rem 0;
          position: absolute;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-miterlimit: 10;
          stroke: #c2c8ce;
          top: 0;
          vertical-align: middle;
          width: 2rem;
          @media #{$laptop} {
            height: 3rem;
            width: 3rem;
          }
        }
        span {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: keep-all;
        }
      }
    }
    &.active &-item {
      opacity: 1;
      &:nth-child(1) {transition-delay: 0.15s;}
      &:nth-child(2) {transition-delay: 0.3s;}
      &:nth-child(3) {transition-delay: 0.45s;}
      &:nth-child(4) {transition-delay: 0.6s;}
      &:nth-child(5) {transition-delay: 0.75s;}
      &:nth-child(6) {transition-delay: 0.9s;}
    }
    &-image {
      &-ctn {
        margin: 0 auto;
        background: none;
        @media #{$tablet} {
          position: absolute;
          top: 0;
          right: 45%;
          width: 50%;
          height: 100%;
          &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
          }
        }
      }
      & {
        margin: 0 auto;
        max-width: 22rem;
        padding: 2rem;
        @media #{$tablet} {
          display: none;
        }
      }
    }
  }
  &-additional-image {
    &s {
      clear: both;
      font-size: 0;
      text-align: center;
    }
    & {
      display: inline-block;
      max-width: 18rem;
      padding: 2rem;
      @media #{$laptop} {
        max-width: 22rem;
      }
    }
  }
  &-cta {
    @extend %options-cta-text;
    background: $color--alt;
    border-radius: 0.3rem;
    color: #fff;
    display: block;
    margin: 0 auto;
    padding: 1.5rem;
    text-align: center;
    width: 30rem;
    @media #{$tablet} {
      margin-top: 2rem;
    }
    @media #{$laptop} {
      border-radius: 0;
      margin-top: 4rem;
      padding: 5rem 2rem;
      width: 100%;
    }
  }
  &-cta span {
    display: inline-block;
  }
  .no-touchevents &-cta {
    @media #{$laptop} {
      transition: 0.45s ease-out;
    }
  }
  .no-touchevents &-cta.active,
  .no-touchevents &-cta:hover {
    @media #{$laptop} {
      background-color: $color--secondary;
      color: #fff;
      transition: 0.45s ease-out 0s;
    }
  }
}

.facts {
  display: none;
  overflow: hidden;
  position: relative;
  @media #{$laptop} {
    display: block;
    padding-top: 5rem;
  }
  &-title {
    @extend %facts-title-text;
    text-transform: uppercase;
    color: $color--alt;
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: 2;
  }
  &-ctn {
    font-size: 0;
  }
  &-item {
    display: inline-block;
    padding: 12rem 4rem;
    vertical-align: middle;
    width: 50%;
    @media #{$desktop} {
      padding: 18rem 4rem;
    }
    &-content {
      margin: 0 auto;
      max-width: 45rem;
      text-align: center;
    }
    &-heading {
      @extend %facts-heading-text;
      color: $color--secondary;
      margin-bottom: 1.5rem;
    }
    &-copy {
      @extend %facts-copy-text;
    }
  }
  &-image {
    background-position: center;
    background-size: cover;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
  }
}

.quote {
  background-color: $color--bg-dark;
  overflow: hidden;
  padding: 4rem;
  position: relative;
  @media #{$laptop} {
    padding: 10rem 4rem;
  }
  @media #{$mac} {
    padding: 15rem 4rem 10rem;
  }
  &-bg {
    background-size: 36rem;
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    transform: translate(-50%,-50%) rotate(45deg);
    width: 300%;
    height: 300%;
  }
  &-ctn {
    background-color: $color--bg-light;
    color: $color--secondary;
    margin: 0 auto;
    max-width: 32rem;
    padding: 4rem 2rem;
    position: relative;
    text-align: center;
    @media #{$tablet} {
      max-width: 60rem;
    }
    @media #{$laptop} {
      max-width: 80rem;
      padding: 6rem 2rem;
    }
    @media #{$mac} {
      padding: 8rem 2rem;
    }
  }
  &-content {
    opacity: 0;
    padding: 0 2rem;
    transition: 0.6s ease-out;
    @media #{$tablet} {
      padding: 0 6rem;
    }
    @media #{$laptop} {
      padding: 0 8rem;
    }
    @media #{$mac} {
      padding: 0 13rem;
    }
  }
  &-ctn.active  &-content{
    opacity: 1;
  }
  &-text {
    @extend %quote-text;
    position: relative;
    p {
      @extend %quote-text;
    }
    svg {
      width: 1em;
      height: 1em;
      fill: $color--secondary;
      &.left,
      &.right {
        position: absolute;
        top: 0;
      }
      &.left {
        right: 100%;
      }
      &.right {
        left: 100%;
        transform: rotate(180deg);
      }
    }
  }
  &-icon {
    height: 5rem;
    margin: 1.5rem auto;
    width: 5rem;
    @media #{$laptop} {
      height: 8rem;
      margin: 2.5rem auto 2.5rem;;
      width: 8rem;
    }
    svg {
      height: 5rem;
      stroke-dasharray: 141;
      stroke-dashoffset: 141;
      transition: 0.6s ease-out;
      width: 5rem;
      .bg {
        opacity: 0;
        stroke-dashoffset: 0;
        transition: 0.6s ease-out 0s;
      }
      @media #{$laptop} {
        height: 8rem;
        width: 8rem;
      }
    }
  }
  &-ctn.active &-icon svg {
    stroke-dashoffset: 0;
    transition: 0.8s linear 0.6s;
    .bg {
      opacity: 1;
      transition: 0.45s ease-out 1.2s;
    }
  }
  &-author {
    @extend %quote-author-text;
    margin-bottom: 1rem;
    @media #{$mac} {
      margin-bottom: 1.5rem;
    }
    &:before {
      content: "-";
      margin-right: 0.3rem;
    }
    &-title {
      @extend %quote-title-text;
      text-transform: uppercase;
      color: rgba($color--secondary, 0.7);
      letter-spacing: 0.2em;
    }
  }
}