// ==========================================================================
// Global Configs
// ==========================================================================

// Text
  $base__font-size : 62.5%; // 1rem == 10px

// Font Weights
  $font-weight--thin       : 100;
  $font-weight--light      : 200;
  $font-weight--book       : 300;
  $font-weight--normal     : 400;
  $font-weight--semibold   : 500;
  $font-weight--bold       : 700;
  $font-weight--heavy      : 800;
  $font-weight--ultra-bold : 900;

// Font Stacks
// font-family: "azo-sans-web",sans-serif; 200,200i,300,300i,400,400i,500,500i,700,700i,900,900i
  $font-family--primary   : "azo-sans-web", sans-serif; // 300, 400, 500, 700
  $font-family--secondary : "azo-sans-uber", sans-serif; // 400

// Descriptive Base Colors

  $white : #f5f4f2;
  $black : #222222;
  $gray  : #aeb6bf;
  $red   : #ee374b;

  $color--primary   : #666666;
  $color--secondary : #35495e;
  $color--alt       : #ee374b;
  $color--bg-light  : #f5f4f2;
  $color--bg-dark   : #aeb6bf;
  $color--input-bg  : #f5f4f2;


// Responsive Sizes
  $mobile-only : "(max-width: 767px)";
  $tablet      : "(min-width: 768px)";
  $laptop      : "(min-width: 1024px)";
  $desktop     : "(min-width: 1200px)";
  $mac         : "(min-width: 1400px)";
// @media #{$mobile-only} {
//   // code here
// }
