.hero {
  background-color: $color--bg-light;
  padding: 14rem 0 10rem;
  position: relative;
  text-align: center;
  @media #{$laptop} {
    padding: 20rem 0 10rem;
  }
  &:before {
    background-image: url(./assets/img/red-dot-bg.png);
    background-repeat: repeat-x;
    background-size: auto 100%;
    content: "";
    display: block;
    height: 16rem;
    left: 0;
    position: absolute;
    top: 0;
    transform-origin: 100% 0;
    transform: rotate(1deg);
    width: 100%;
    z-index: 1;
    @media #{$tablet} {
      height: 18rem;
    }
    @media #{$laptop} {
      height: 30rem;
    }
  }
  &-title {
    @extend %hero-title-text;
    color: $color--secondary;
    text-transform: uppercase;
    opacity: 0;
    padding: 0 2rem;
    position: relative;
    transform: translate(0,5rem);
    transition: 0.8s ease-out;
    z-index: 3;
    .loaded & {
      opacity: 1;
      transform: translate(0,0);
    }
  }
  &-divider {
    @extend %divider-svg;
    display: none;
    position: relative;
    z-index: 3;
    @media #{$laptop} {
      display: block;
      height: 13rem;
      margin: 1rem 0 2.5rem;
    }
    i {
      display: block;
      height: 0;
      transition: 0.6s ease-out 0.8s;
      width: 100%;
    }
    .loaded & i {
      height: 100%;
    }
  }
  &-highlights {
    @extend %clearfix;
    display: none;
    position: relative;
    z-index: 3;
    @media #{$laptop} {
      display: block;
      font-size: 0;
      margin: 0 auto;
      max-width: 140rem;
      opacity: 0;
      transition: 0.6s ease-out 1.4s;
      width: 100%;
      .loaded & {
        opacity: 1;
      }
    }
    &-item {
      display: inline-block;
      padding: 0 4rem;
      vertical-align: top;
      width: 35%;
      &:nth-of-type(1) {
        float: left;
      }
      &:nth-of-type(2) {
        float: right;
      }
      &-icon {
        margin-bottom: 1.5rem;
        svg {
          display: block;
          height: 5rem;
          margin: 0 auto;
          width: 5rem;
          @media #{$laptop} {
            height: 7rem;
            width: 7rem;
          }
          &.stars-icon {
            stroke-dasharray: 71;
            stroke-dashoffset: 71;
          }
          &.puzzle-icon {
            stroke-dasharray: 196;
            stroke-dashoffset: 196;
          }
          .bg {
            opacity: 0;
            stroke-dashoffset: 0;
          }
          .loaded & {
            stroke-dashoffset: 0;
            transition: 2s ease 1.5s;
            & .bg {
              opacity: 1;
              transition: 1s ease-out 2.5s;
            }
          }
        }
      }
      &-title {
        @extend %hero-highlight-heading-text;
        color: $color--secondary;
        margin-bottom: 1rem;
        @media #{$desktop} {
          margin-bottom: 1.5rem;
        }
      }
      &-text {
        @extend %hero-highlight-copy-text;
        max-width: 360px;
        margin: 0 auto;
      }
    }
  }
  &-img {
    &-ctn {
      height: 100%;
      left: 50%;
      position: absolute;
      top: 0;
      transform: translate(-50%,0);
      width: 30%;
    }
    & {
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
  &-cta {
    margin-top: 2.5rem;
    opacity: 0;
    position: relative;
    text-align: center;
    transition: 0.6s ease-out 0.6s;
    z-index: 3;
    @media #{$laptop} {
      margin-top: 10rem;
    }
    @media #{$laptop} {
      transition-delay: 2.8s;
    }
    .loaded & {
      opacity: 1;
    }
  }
}