.plans {
  overflow: hidden;
  padding-top: 6rem;
  @media #{$tablet} {
    padding-bottom: 3rem;
    padding-top: 6rem;
  }
  @media #{$laptop} {
    padding-top: 16rem;
  }
  &:before {
    background-image: url(./assets/img/red-dot-bg.png);
    background-repeat: repeat-x;
    background-size: auto 100%;
    content: "";
    display: block;
    height: 16rem;
    left: 0;
    position: absolute;
    top: 0;
    transform-origin: 100% 0;
    transform: rotate(1deg) translate(0,-6rem);
    width: 100%;
    z-index: 1;
    @media #{$tablet} {
      height: 18rem;
    }
    @media #{$laptop} {
      height: 30rem;
    }
  }
  &-title {
    @extend %plans-title-text;
    color: $color--secondary;
    padding: 0 2rem;
    text-align: center;
    text-transform: uppercase;
  }
  &-highlights {
    display: none;
    @media #{$laptop} {
      display: block;
      font-size: 0;
      margin: 4rem auto 6rem;
      max-width: 120rem;
    }
    &-item {
      display: inline-block;
      padding: 2rem;
      text-align: center;
      vertical-align: top;
      width: 50%;
      &-content {
        margin: 0 auto;
        max-width: 45rem;
        position: relative;
      }
      &-heading {
        @extend %plans-highlight-heading-text;
        color: $color--secondary;
        padding: 3rem 0 1rem;
      }
      &-copy {
        @extend %plans-highlight-copy-text;
      }
    }
  }
  &-ctn {
    font-size: 0;
    margin: 3rem auto 0;
    max-width: 120rem;
    outline: none;
    @media #{$laptop} {
      margin: 0 auto;
      padding: 0 1rem;
    }
  }
  &-ctn &-item {
    @media #{$tablet} {
      opacity: 0;
      transform: translate(0, 5rem);
      &:first-child {
        transition: 0.6s ease-out 0s;
      }
      &:nth-child(2) {
        transition: 0.6s ease-out 0s;
      }
      &:last-child {
        margin-right: 0;
        transition: 0.6s ease-out 0s;
      }
    }
  }
  &-item {
    margin: 0 auto;
    max-width: 40rem;
    padding: 0 0.5rem;
    width: 75%;
    .flickity-enabled & {
      transform: scale(0.9);
      transition: 0.2s ease-out;
      &.is-selected {
        transform: scale(0.99);
      }
    }
    @media #{$tablet} {
      display: inline-block;
      padding: 0 1rem;
      width: 33.33%;
    }
    &-content {
      box-shadow: 0 0.5rem 2rem #ccc;
      border: 0.1rem solid $color--secondary;
      border-radius: 0.3rem;
      color: $color--secondary;
      position: relative;
      text-align: center;
      transition: 0.6s ease-out;
      overflow: hidden;
      @media #{$laptop} {
        border-radius: 1rem;
        border: 0.2rem solid $color--secondary;
      }
      &:after {
        content: "";
        background-color: $anchor-text-decoration;
        display: block;
        height: 100%;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 100%;
        .flickity-enabled & {
          opacity: 0.2;
          transition: 0.2s ease-out;
        }
        .flickity-enabled .is-selected & {
          opacity: 0;
        }
      }
    }
    &-title {
      @extend %plan-title-text;
      background-color: #f5f7f9;
      padding: 1.5rem;
      text-transform: uppercase;
      transition: 0.6s ease-out;
      @media #{$laptop} {
        padding: 2rem 1.5rem 1.5rem;
      }
    }
    &-details {
      padding: 0 1.5rem;
      transition: 0.6s ease-out;
    }
    &-price {
      @extend %plan-price-text;
      padding: 2rem;
      @media #{$tablet} {
        padding: 2rem 1em;
      }
      @media #{$laptop} {
        padding: 2rem;
      }
    }
    &-description {
      @extend %plan-description-text;
      background-color: #f5f7f9;
      border-radius: 0.3rem;
      padding: 1.5rem;
      transition: 0.6s ease-out;
      @media #{$tablet} {
        padding: 1.5rem 1;
      }
      @media #{$laptop} {
        padding: 1.5rem;
      }
    }
    &-icon {
      font-size: 0;
      margin: 2rem auto 0;
      @media #{$laptop} {
        margin: 2.5rem auto 0;
      }
      svg {
        display: inline-block;
        height: 5rem;
        margin-right: 1rem;
        width: 5rem;
        &:last-child {
          margin-right: 0;
        }
        @media #{$tablet} {
          height: 4rem;
          width: 4rem;
        }
        @media #{$laptop} {
          height: 5rem;
          width: 5rem;
        }
        .bg {
          opacity: 0.2;
        }
      }
    }
    &-fequency {
      @extend %plan-frequency-text;
      color: $color--secondary;
      border-bottom: 0.3rem solid #ebe9e5;
      padding: 2rem;
      @media #{$laptop} {
        padding: 2rem;
      }
    }
    &-cost {
      @extend %plan-cost-text;
      color: $color--secondary;
      padding: 2rem;
    }
    &-cta {
      padding: 0 1rem 4rem;
      .btn {
        width: 80%;
      }
    }
    &:nth-child(2) & {
      &-title {
        background-color: #f5f7f9;
        background-color: $color--secondary;
        color: #fff;
      }
      &-content {
        background-color: $color--bg-light;
        border-color: $color--alt;
        box-shadow: 0 0rem 3rem transparent;
        @media #{$tablet} {
          box-shadow: 0 0rem 3rem $color--alt;
        }
      }
      &-details {
        padding: 0 1.5rem;
      }
      &-description {
        background-color: #cfd2d6
      }
    }
    &:nth-child(2).is-selected & {
      &-content {
        box-shadow: 0 0rem 3rem $red;
      }
    }
  }
  &-ctn.active &-item {
    @media #{$tablet} {
      opacity: 1;
      transform: translate(0, 0);
      &:first-child{
        transition-delay: 0s;
      }
      &:nth-child(2){
        transition-delay: 0.2s;
      }
      &:last-child{
        transition-delay: 0.4s;
      }
    }
  }
  .flickity-page-dots {
    @extend %pagers;
    padding: 3rem 0 4rem;
    @media #{$laptop} {
      display: none;
    }
  }
  .flickity-prev-next-button {
    display: none;
  }
}