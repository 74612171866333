.footer {
  background-color: $color--secondary;
  color: #fff;
  padding: 5rem 2rem;
  text-align: center;
  @media #{$laptop} {
    padding: 10rem 0 3rem;
  }
  &-ctn {
    font-size: 0;
    @media #{$laptop} {
      padding: 0 0 5rem;
    }
  }
  &-item {
    @extend %footer-text;
    display: block;
    position: relative;
    @media #{$laptop} {
      display: inline-block;
      padding: 0 4%;
      vertical-align: middle;
    }
    @media #{$mac} {
      padding: 0 7.5rem;
    }
    &:after {
      background-color: rgba(#fff, 0.1);
      content: "";
      display: block;
      height: 0.1rem;
      margin: 5rem auto;
      width: 10rem;
      @media #{$laptop} {
        height: 12rem;
        margin: 0;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0,-50%);
        width: 0.1rem;
      }
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
  }
  &-contact {
    @extend %footer-contact-text;
    &-email,
    &-phone {
      margin-bottom: 1.5rem;
      @media #{$laptop} {
        margin-bottom: 2rem;
      }
      a {
        .no-touchevents & {
          transition: 0.3s ease-out;
        }
        .no-touchevents &:hover {
          color: $color--alt;
        }
      }
    }
    &-social {
      &-link {
        .icon {
          display: inline-block;
          height: 2rem;
          margin-right: 1rem;
          width: 2rem;
          svg {
            fill: #fff;
            height: 2rem;
            width: 2rem;
            @media #{$laptop} {
              height: 2.5rem;
              width: 2.5rem;
            }
          }
          @media #{$laptop} {
            height: 2.5rem;
            width: 2.5rem;
          }
        }
        &:last-child .icon {
          margin-right: 0;
        }
        .icon-text {
          display: none;
        }
        .no-touchevents & svg {
          transition: 0.3s ease-out;
        }
        .no-touchevents &:hover svg {
          fill: $color--alt;
        }
      }
    }
  }
  &-address {
    @extend %footer-address-text;
    display: none;
    address {
      font-style: normal;
    }
    @media #{$laptop} {
      display: inline-block;
      line-height: 1.6;
    }
  }
  &-newsletter {
    &-text {
      @extend %footer-newsletter-text;
      margin-bottom: 1.5rem;
      @media #{$laptop} {
        text-align: left;
      }
    }
    &-form {
      position: relative;
      input[type="text"],
      button[type="submit"] {
        display: inline-block;
        outline: 0;
        vertical-align: middle;
      }
      input[type="text"] {
        @extend %newsletter-input-text;
        color: $color--secondary;
        background-color: #fff;
        border: 1px solid transparent;
        border-radius: 0.6rem;
        line-height: 1;
        padding: 2rem;
        width: 18rem;
        @media #{$laptop} {
          width: 20rem;
        }
        @media #{$desktop} {
          width: 22rem;
        }
        &::placeholder {
          color: $color--secondary;
          opacity: 1;
          transition: 0.2s ease-out;
        }
        &:focus::placeholder {
          opacity: 0.2;
        }
        &.invalid {
          border: 1px solid red;
        }
      }
      button[type="submit"] {
        @extend %newsletter-submit-text;
        background-color: $color--alt;
        border: 1px solid transparent;
        border-radius: 4rem;
        color: #fff;
        display: inline-block;
        margin-left: 1rem;
        outline: 0;
        padding: 1.8rem 3rem;
        text-transform: uppercase;
      }
      .no-touchevents & button[type="submit"] {
        transition: 0.3s ease-out;
      }
      .no-touchevents & button[type="submit"]:hover {
        background-color: $color--primary;
        color: #fff;
      }
    }
    &-message {
      position: absolute;
      top: 100%;
      margin-top: 1rem;
      width: 100%;
      text-align: center;
    }
  }
  &-legal {
    @extend %legal-text;
    padding-top: 5rem;
    @media #{$laptop} {
      padding-top: 2rem;
    }
  }
}