.orthodontists {
  padding-bottom: 5rem;
  padding-top: 4rem;
  text-align: center;
  @media #{$laptop} {
    display: inline-block;
    padding-bottom: 9rem;
    padding-top: 10rem;
    width: 50%;
  }
  @media #{$mac} {
    padding-top: 15rem;
  }
  & {
    opacity: 0;
    transform: translate(0, 5rem);
    transition: 0.6s ease-out 0s;
    .modals.active & {
      opacity: 1;
      transform: translate(0, 0);
      transition-delay: 0.2s;
    }
  }
  &-title {
    @extend %orthodontist-title-text;
    color: $color--secondary;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    @media #{$laptop} {
      margin-bottom: 1.5rem;
    }
  }
  &-text {
    @extend %orthodontist-copy-text;
    margin-bottom: 2rem;
    @media #{$laptop} {
      margin-bottom: 2.4rem;
    }
  }
  &-cta {}
  &-modal {
    -webkit-overflow-scrolling: touch;
    background-color: $color--bg-light;
    bottom: 0;
    left: 0;
    opacity: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    transition: opacity 0.45s ease-out, visibility 0.45s ease-out;
    visibility: hidden;
    width: 100%;
    z-index: 9;
    @media #{$laptop} {
      background-color: $color--bg-dark;
      padding: 14rem 5rem 15rem;
    }
    &-title {
      @extend %orthodontist-modal-title-text;
      color: $color--secondary;
    }
    .orthodontists-active & {
      display: block;
      opacity: 1;
      overflow: scroll;
      visibility: visible;
    }
    &-bg {
      bottom: 0;
      display: block;
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      z-index: 0;
    }
    &-content {
      margin: 0 auto;
      padding: 6rem 0 7rem;
      position: relative;
      text-align: center;
      z-index: 1;
      @media #{$laptop} {
        background-color: #fff;
        max-width: 110rem;
        padding: 20rem 5rem 15rem;
      }
    }
    .orthodontists-form-close {
      height: 3rem;
      position: absolute;
      right: 2.5rem;
      top: 3rem;
      width: 3rem;
      cursor: pointer;
      @media #{$laptop} {
        height: 4rem;
        right: 4rem;
        top: 4rem;
        width: 4rem;
      }
      svg {
        height: 3rem;
        stroke-width: 2;
        stroke: $color--secondary;
        transform: rotate(45deg);
        width: 3rem;
        @media #{$laptop} {
          height: 4rem;
          width: 4rem;
        }
      }
    }
  }
  &-highlights {
    display: block;
    font-size: 0;
    margin: 3rem auto 6rem;
    max-width: 120rem;
    @media #{$laptop} {
      margin: 4rem auto 6rem;
    }
    &-item {
      display: block;
      padding: 2rem;
      text-align: center;
      width: 100%;
      @media #{$laptop} {
        display: inline-block;
        vertical-align: top;
        width: 50%;
      }
      &-content {
        margin: 0 auto;
        max-width: 45rem;
        position: relative;
      }
      &-heading {
        @extend %orthodontists-modal-highlight-heading-text;
        color: $color--secondary;
        padding: 1rem 0;
        @media #{$laptop} {
          padding: 3rem 0 1rem;
        }
      }
      &-copy {
        @extend %orthodontists-modal-highlight-copy-text;
      }
    }
  }
  &-form {
    margin: 0 -0.5rem;
    &-ctn {
      font-size: 0;
      margin: 0 auto;
      max-width: 75rem;
      padding: 0 4rem;
      @media #{$laptop} {
        padding: 0 3rem;
      }
    }
    &-title {
      @extend %orthodontist-modal-form-lead-text;
      color: $color--secondary;
      margin-bottom: 1.5rem;
      @media #{$laptop} {
        margin-bottom: 2.5rem;
        margin-top: 0.6rem;
      }
    }
    &-text {
      @extend %orthodontist-modal-form-heading-text;
      color: $color--secondary;
      margin-bottom: 2.5rem;
      padding: 0 2rem;
    }
    &-item {
      display: inline-block;
      padding: 0.5rem;
      width: 50%;
      &.wide {
        width: 100%;
        &.hp {
          height: 0;
          width:0;
          margin: 0;
          padding: 0;
          border: 0;
          overflow: hidden;
        }
      }
    }
    input[type="text"],
    textarea {
      @extend %input-text;
      background-color: #fff;
      border: 1px solid transparent;
      border-radius: 0.6rem;
      color: $color--secondary;
      display: block;
      outline: 0;
      padding: 1.5rem;
      width: 100%;
      @media #{$laptop} {
        background-color: $color--input-bg;
        padding: 2rem;
      }
      &::placeholder {
        color: $color--secondary;
        opacity: 1;
        transition: 0.2s ease-out;
      }
      &:focus::placeholder {
        opacity: 0.2;
      }
    }
    input[type="text"].invalid {
      border: 1px solid red;
    }
    .btn {
      display: inline-block;
      margin-top: 2rem;
      width: 15rem;
      @media #{$laptop} {
        margin-top: 3rem;
        width: 24rem;
      }
    }
  }
  &-response {
    @extend %form-response;
    color: $color--secondary;
    margin-top: 1rem;
  }
}