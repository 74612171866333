.reviews {
  background-color: $color--bg-light;
  overflow: hidden;
  z-index: 4;
  &-content {
    background-color: $color--bg-dark;
    overflow: hidden;
    padding: 3rem 0 0;
    @media #{$laptop} {
      background: linear-gradient($color--bg-light, $color--bg-dark 20%);
      opacity: 0;
      padding: 10rem 2rem;
      transition: 0.8s ease-out;
      &.active {
        opacity: 1;
      }
    }
    &:before {
      background-image: url(./assets/img/white-dot-bg.png);
      background-repeat: repeat-x;
      background-size: auto 100%;
      content: "";
      display: block;
      height: 85%;
      left: 0;
      position: absolute;
      bottom: 0;
      transform-origin: 100% 0;
      transform: rotate(-1deg);
      width: 100%;
      z-index: 1;
    }
  }
  &-title {
    @extend %reviews-title-text;
    color: $color--secondary;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 1.5rem;
    position: relative;
    z-index: 2;
    @media #{$laptop} {
      margin-bottom: 4rem;
    }
  }
  &-slideshow {
    outline: 0;
    .flickity-slider {
      transform: none !important;
    }
    .flickity-viewport {
      position: relative;
    }
    &-ctn {
      margin: 0 auto;
      max-width: 80%;
      position: relative;
      z-index: 2;
      @media #{$laptop} {
        background-color: $color--bg-light;
        border-radius: 0.6rem;
        max-width: 70rem;
      }
    }
    &-slide {
      background-color: $color--bg-light;
      border-radius: 0.3rem;
      height: 100%;
      left: 0 !important;
      text-align: center;
      top: 0 !important;
      width: 100%;
      z-index: 1;
      &-content {
        opacity: 0;
        padding: 4rem 4rem;
        transition: opacity 0.6s ease-in-out;
        @media #{$laptop} {
          padding: 6rem 8rem;
        }
        @media #{$mac} {
          padding: 6rem 10rem;
        }
      }
      &.is-selected {
        z-index: 2;
      }
      &.is-selected &-content {
        opacity: 1;
      }
      &-content {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
      }
    }
    &-quote {
      @extend %reviews-quote-text;
      line-height: 1.4;
      margin: 0 auto 2rem;
      max-width: 60rem;
      @media #{$laptop} {
        margin-bottom: 4rem;
      }
      @media #{$mac} {
        margin-bottom: 5rem;
      }
      &:before {
        content: "“";
        display: inline-block;
        padding: 0.2rem;
      }
      &:after {
        content: "”";
        display: inline-block;
        padding: 0.2rem;
      }
    }
    &-author {
      @extend %reviews-quote-author-text;
      color: $color--secondary;
      margin-bottom: 1rem;
      @media #{$mac} {
        margin-bottom: 1.5rem;
      }
    }
    &-details {
      @extend %reviews-quote-title-text;
      color: rgba($color--secondary, 0.4);
      text-transform: uppercase;
    }
    .flickity-page-dots {
      @extend %pagers;
      padding: 2rem 0 2rem;
      @media #{$laptop} {
        padding: 0 0 4rem;
      }
    }
    .flickity-prev-next-button {
      display: none;
    }
  }
}